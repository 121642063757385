.slate-ul {
  list-style-type: disc;
}

.slate-ol {
  list-style-type: decimal;
}

.slate-ToolbarButton-active > * {
  color: var(--color-primary-default) !important;
}
