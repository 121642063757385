:local(.container) {
  min-width: 280px;
  background-color: white;
  border-radius: 5px;
  padding: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.075);
}

:local(.header) {
  display: flex;
  height: 40px;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  margin: 8px;
  /* box-sizing: border-box; */
}

:local(.number) {
  font-size: 36px;
  font-weight: bold;
  padding: 5px;
}

:local(.title) {
  font-weight: bold;
  max-width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
}

:local(.image) {
  height: 20px;
  width: 20px;
}

:local(.clickable) {
  cursor: pointer;
}

/* :local(.body) {
  overflow-y: auto;
} */

:local(.body) > * {
  margin: 8px;
}

:local(.buttons) > * {
  margin: 8px;
}

:local(.input) {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #333;
  width: 80%;
  /* box-sizing: border-box; */
}
