:local(.container) {
  max-width: 300px;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  margin: 8px;
}

:local(.header) {
  display: flex;
  justify-content: space-between;
  /* padding: 8px; */
}
:local(.headerdelete) {
  display: flex;
  justify-content: flex-start;
  /* padding: 8px; */
}

:local(.body) {
  /* padding: 8px; */
}

:local(.content) {
  text-align: left;
  margin: 0;
  padding: 8px;
}

:local(.author) {
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: '#fff';
}

:local(.criteria) {
  text-align: left;
  font-style: italic;
  margin: 0;
  padding: 8px;
  font-size: small;
}

:local(.votes) {
  text-align: left;
  /* font-style: italic; */
  font-weight: bold;
  margin: 0;
  padding: 8px;
  font-size: small;
}

:local(.temporary) {
  text-align: left;
  margin: 0;
  padding: 8px;
  opacity: 0.33;
}

:local(.exit) {
  width: 20px;
  height: 20px;
  text-align: center;
  color: grey;
  vertical-align: middle;
}

:local(.clickable) {
  cursor: pointer;
}

:local(.active) {
  background-color: rgba(105, 90, 249, 0.5);
}

:local(.highlighted) {
  background-color: rgba(255, 215, 86, 0.5);
}

:local(.highlighted.active) {
  background-color: rgba(171, 80, 194, 0.5);
}
