.react-router-tabs {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.react-router-tabs > .nav-tab {
  flex: 1;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border-bottom: solid 4px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  white-space: nowrap;
}

.react-router-tabs > .nav-tab:hover {
  background-color: transparent;
  opacity: 1;
}

.react-router-tabs > .nav-tab.active {
  background-color: transparent;
  border: none;
  color: var(--color-primary-default);
  border-bottom: solid 4px var(--color-primary-default);
  opacity: 1;
}
