@tailwind base;
/* see https://github.com/niklasvh/html2canvas/issues/2775#issuecomment-1204988157 */
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-gray-soft: #f2f2f2;

    --color-black-soft: #666666;
    --color-black-default: #161618;
    --color-black-strong: #969696;
    --color-black-medium: #323232;

    --color-surfaces-soft: #fef9f5;
    --color-surfaces-default: #f9fafa;
    --color-surfaces-strong: #f3e6dc;
    --color-surfaces-strongest: #e7cdb9;
    --color-surfaces-divider: #e4e4e4;

    --color-primary-soft: #deebff;
    --color-primary-medium: #4e94ff;
    --color-primary-default: #0800c3;
    --color-primary-strong: #00188e;

    --color-secondary-soft: #def4ff;
    --color-secondary-default: #1bb4ff;

    --color-success-soft: #e3fcef;
    --color-success-medium: #15c388;
    --color-success-default: #00875a;

    --color-warning-soft: #f8efe5;
    --color-warning-medium: #f5c99a;
    --color-warning-default: #f0a557;

    --color-danger-soft: #ffebe6;
    --color-danger-medium: #f77e60;
    --color-danger-default: #de350b;
  }

  .env-dev {
    --color-primary-soft: #d8f3dc;
    --color-primary-default: #40916c;
    --color-primary-strong: #1b4332;
  }

  .env-exp {
    --color-primary-soft: #ffda3d;
    --color-primary-default: #ec9f05;
    --color-primary-strong: #fc7a1e;
  }

  svg {
    vertical-align: baseline;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    @apply border-primary text-primary;
  }

  .markdown-editor h1 {
    @apply text-2xl text-gray-900;
  }

  .markdown-editor h2 {
    @apply text-xl font-semibold text-gray-700;
  }

  .markdown-editor h3 {
    @apply text-lg text-gray-500;
  }

  .markdown-editor p {
    @apply text-base;
  }

  .markdown-editor ol {
    @apply list-decimal;
  }

  .markdown-editor ul {
    @apply list-disc;
  }

  .markdown-editor div[contenteditable='false'] .caption:empty {
    @apply hidden;
  }
  .markdown-editor div[contenteditable='true'] .caption:empty {
    @apply block;
  }

  .markdown-editor a {
    @apply text-primary underline !important;
  }

  .markdown-editor .notice-block.tip .icon {
    @apply text-primary !important;
  }

  .markdown-editor .notice-block.warning .icon {
    @apply text-warning !important;
  }

  .markdown-editor .notice-block.info .icon {
    @apply text-primary !important;
  }
}

@layer utilities {
  .bi-auto {
    break-inside: auto;
  }
  .bi-avoid {
    break-inside: avoid;
  }
  .bi-avoid-page {
    break-inside: avoid-page;
  }
  .bi-avoid-column {
    break-inside: avoid-column;
  }
  .bi-avoid-region {
    break-inside: avoid-region;
  }
  .print-color-exact {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .print-color-economy {
    print-color-adjust: economy;
    -webkit-print-color-adjust: economy;
  }
}

@media print {
  @page {
    margin: 0 1.6cm;
  }
}
