#mindmap {
  font-family: 'Poppins';
}

#mindmap .map-container .map-canvas {
  background-color: white;
}

#mindmap .map-container .map-canvas .selected {
  outline: none;
}

#mindmap .map-container .lt {
  width: auto;
  display: none;
}

#mindmap .map-container .map-canvas root tpc {
  display: block;
  color: #ffffff;
  padding: 10px 15px;
  background-color: var(--color-primary-default);
  border-radius: 40px;
  font-size: 25px;
  white-space: pre-wrap;
  outline: none;
  max-width: 500px;
  text-align: center;
}

#mindmap .map-container .box > grp > t > tpc {
  background-color: #ffffff;
  border: 1px solid var(--color-black-soft);
  border-radius: 40px;
  color: var(--color-black-default);
  font-weight: 600;
  padding: 8px 16px;
  margin: 0;
  outline: none;
  font-size: 28px;
}

#mindmap .map-container .subLines path {
  stroke: var(--color-black-soft);
}

#mindmap .map-container .lines path {
  stroke: var(--color-black-soft);
}
