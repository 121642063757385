:local(.container) {
  display: flex;
  /* overflow-x: scroll; */
  /* overflow-y: visible; */
  /* YOU NEED TO HAVE A WIDTH WITH ANY PX NUMBER */
  width: 500px;
  /* THEN FLEX: 1 takes over to fill the screen  */
  flex: 1;
  /* I KNOW ITS WEIRD BUT KEEP IT */

  /* background-color: rgba(0, 0, 0, 0.05); */
}

:local(.container) > * {
  margin: 8px;
  height: fit-content;
}
