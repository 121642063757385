:local(.container) {
  display: flex;
  /* box-sizing: border-box; */
  width: auto;
  height: 66px;
  max-height: 66px;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  /* overflow-y: visible; */
}

:local(.thirdLeft) {
  display: flex;
  flex: 1;
}

:local(.thirdCenter) {
  display: flex;
  flex: 1;
}

:local(.thirdRight) {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

:local(.button) {
  margin: 4px;
}

:local(.tooltip) {
  color: #ccc;
  padding: 16px;
  font-size: 14px;
  text-align: left;
}

:local(.modal) {
  /* background: blue; */
  position: relative;
  z-index: 100;
}

:local(.modalContainer) {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f5f5f5;
  top: 0px;
  left: 5px;
  padding: 5px;
  max-height: 45vh;
  max-width: 300px;
  min-width: 270px;
  /* overflow-x: scroll;*/
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.42);
}

:local(.buttonContainer) {
  display: flex;
  justify-content: center;
}

:local(.flexbox) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

:local(.label) {
  font-size: 16px;
  padding: 8px;
  text-align: left;
}

:local(.clickable) {
  cursor: pointer;
}

:local(.dividerHorizontal) {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
